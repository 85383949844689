<template>
  <div class="m-nav">
    <div class="item other-tab">
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          <i class="el-icon-s-fold" style="font-size: 24px"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="Home">首页</el-dropdown-item>
          <el-dropdown-item command="Government">政务动态</el-dropdown-item>
          <el-dropdown-item command="IndustryIndex">行业动态</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="item active">服务大厅</div>
    <!-- <div class="item" @click="recommend">精品推荐</div> -->
    <div class="item" @click="cooperation">合作伙伴</div>
  </div>
</template>

<script>
export default {
  name: "mNav",
  methods: {
    handleCommand(command) {
      this.$router
        .push({
          name: command,
        })
        .catch((err) => {});
    },
    recommend() {
      window.open("http://oow.miaocang.cc/search");
    },
    cooperation() {
      let scrollHeight =
        document.documentElement.scrollHeight | document.body.scrollHeight;
      document.documentElement.scrollTop = scrollHeight;
      document.body.scrollTop = scrollHeight;
    }
  },
};
</script>

<style lang="less">
.el-dropdown-menu__item {
  font-size: 22px;
  line-height: 54px;
}
.m-nav {
  height: 62px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .item {
    font-size: 22px;
    color: #909399;
    &.active {
      font-weight: bold;
      color: #20a162;
    }
  }
  .other-tab {
    width: 40px;
    text-align: center;
  }
}
</style>