<template>
    <div class="m-address-pos">
        <div class="pos">当前位置：</div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item><a href="/">活动管理</a></el-breadcrumb-item> -->
            <el-breadcrumb-item v-for="(item, index) in levelGroup" :key="index">{{item}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
export default {
    name: 'MAddressPosition',
    data() {
        return {
            levelGroup: []
        }
    },
    created() {
        let routerInfo = this.$route
        // console.log('from address: ', routerInfo)
        this.levelGroup = routerInfo.meta.level
    }
}
</script>

<style lang="less">
.m-address-pos {
    .el-breadcrumb {
        font-size: 20px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0;
    .pos {
        font-size: 20px;
        color: #666;
        padding-right: 10px;
    }
}
</style>